(function (){
	const body = document.querySelector('body');

	const showVideoButton = document.querySelector('.about__video-link');
	const modalVideo = document.querySelector('.modal-video');
	const modalVideoCross = modalVideo.querySelector('.modal-video__cross');
	const videoPlayer = modalVideo.querySelector('.modal-video__video');

	showVideoButton.addEventListener('click', () => showModalVideo('open'));
	modalVideoCross.addEventListener('click', () => showModalVideo('close'));

	function showModalVideo(action) {
		if (action === 'open') {
			body.classList.add('popup-active');
			modalVideo.classList.remove('hidden');
			videoPlayer.play();
		} else if (action === 'close') {
			body.classList.remove('popup-active');
			modalVideo.classList.add('hidden');
			videoPlayer.pause();
		}
	}
})();
