(function () {
	const newsSwiper = new Swiper('.about-swiper', {
		// Optional parameters
		direction: 'horizontal',
		slidesPerView: 1,
		centeredSlides: false,
		spaceBetween: 24,

		breakpoints: {
			476: {
				slidesPerView: 2,
				spaceBetween: 24,
			},
			744: {
				slidesPerView: 3,
				spaceBetween: 40,
			}
		},

		pagination: {
			el: '.about-swiper-pagination',
			type: 'bullets',
		},
	});
})();
