// 'use strict';
//
// const landGeneralPlan = () => {
// 	const generalPlanWrapper = document.querySelector('.genplane_svg');
//
// 	const generateErrorMessage = (errorText) => console.log(`${errorText}`);
//
// 	if (!generalPlanWrapper) {
// 		generateErrorMessage('Plan wrapper is not defined')
// 		return false;
// 	}
//
// 	let svgPathArray = Array.from(generalPlanWrapper.querySelectorAll('path'));
//
// 	const getLocationsInfo = () => {
// 		const locationsInfo = Array.from(JSON.parse(document.querySelector('.js_angles_info').dataset.angles));
//
// 		if (!locationsInfo) {
// 			generateErrorMessage('Locations info is not defined')
// 		}
//
// 		return locationsInfo;
// 	}
//
// 	const locations = getLocationsInfo();
//
// 	const setLocationOptions = (locationId) => {
// 		const currentLocation = locations.find(location => location.polygon === locationId);
// 		const currentPath = document.getElementById(locationId);
//
// 		if (!currentLocation) {
// 			return false;
// 		}
//
// 		currentPath.classList.add('binded');
//
// 		if (currentLocation.flats[0].extra_data.project === 'Участок без подряда') {
// 			currentPath.classList.add('binded--no-house');
// 		}
//
// 		let currentPathOptions = {
// 			url: `/show/flat-${currentLocation.flats[0].id}`,
// 			conditionalNumber: `${currentLocation.flats[0].conditionalNumber}`,
// 			area: `${currentLocation.flats[0].area / 100}`,
// 			price: `${(currentLocation.flats[0].meterPrice * currentLocation.flats[0].area).toLocaleString('ru')}`,
// 			minPrice: `${(currentLocation.flats[0].minPrice).toLocaleString('ru')}`,
// 			extraData: currentLocation.flats[0].extra_data
// 		};
//
// 		currentPathOptions = JSON.stringify(currentPathOptions);
// 		currentPath.setAttribute('data-info', `${currentPathOptions}`);
//
// 		if ('ontouchstart' in window) {
// 			// touch
// 		} else {
// 			addHandleForHover(currentPath);
// 		}
// 	}
//
// 	const addHandleForHover = (path) => {
// 		const currentPathOptions = JSON.parse(path.dataset.info);
// 		const tooltip = document.querySelector('.genplane__tooltip');
// 		let isTooltipShow = false;
//
// 		const addCurrentPathOptionsToTooltip = () => {
// 			const area = document.querySelector('.js_area');
//
// 			if (area && currentPathOptions.area) {
// 				area.textContent = `${currentPathOptions.area.replace(/\./, ',')} сот.`;
// 			}
//
// 			const priceLot = document.querySelector('.js_price_lot');
//
// 			if (priceLot && currentPathOptions.extraData) {
// 				priceLot.textContent = `${currentPathOptions.price} ₽`;
// 			}
//
// 			const priceLotMin = document.querySelector('.js_price_lot_min');
//
// 			if (priceLotMin && currentPathOptions.extraData) {
// 				if (currentPathOptions.minPrice === '0' || currentPathOptions.minPrice === currentPathOptions.price) {
// 					priceLotMin.classList.add('hidden')
// 					priceLot.classList.remove('plane__wrapper-footer-title--delete')
// 				} else {
// 					priceLotMin.classList.remove('hidden')
// 					priceLot.classList.add('plane__wrapper-footer-title--delete')
// 					priceLotMin.textContent = `${currentPathOptions.minPrice} ₽`;
// 				}
// 			}
//
// 			const project = document.querySelector('.js_project')
//
// 			if (project && currentPathOptions.conditionalNumber) {
// 				project.textContent = `№${currentPathOptions.conditionalNumber}`;
// 			}
//
// 			const buildingStatus = document.querySelector('.js_building_status');
//
// 			if (buildingStatus && currentPathOptions.extraData) {
// 				if (currentPathOptions.extraData.building_status === 'нет') {
// 					buildingStatus.classList.add('hidden');
// 				} else if (currentPathOptions.extraData.building_status === 'стройка') {
// 					buildingStatus.classList.remove('hidden');
// 					buildingStatus.textContent = `Дом строится`;
// 				} else if (currentPathOptions.extraData.building_status === 'готов') {
// 					buildingStatus.classList.remove('hidden');
// 					buildingStatus.textContent = `Дом построен`;
// 				}
// 			}
// 		}
//
// 		const showTooltip = () => {
// 			tooltip.style.display = 'block';
// 			return isTooltipShow = true;
// 		}
// 		const hideTooltip = () => {
// 			tooltip.style.display = 'none';
// 			return isTooltipShow = false;
// 		}
//
// 		path.addEventListener('mouseenter', showTooltip);
//
// 		let windowWidth = window.innerWidth;
//
// 		path.addEventListener('mousemove', (e) => {
// 			addCurrentPathOptionsToTooltip();
//
// 			if (isTooltipShow) {
// 				let xPosition;
//
// 				if ( windowWidth - e.pageX < 358) {
// 					xPosition = e.pageX - 190;
// 				} else {
// 					xPosition = e.pageX + 20;
// 				}
//
// 				tooltip.style.top = `${e.pageY + 10}px`;
// 				tooltip.style.left = `${xPosition}px`;
// 			}
// 		});
//
// 		path.addEventListener('mouseleave', hideTooltip);
// 	}
//
// 	svgPathArray.map(path => {
// 		const id = path.getAttribute('id');
//
// 		setLocationOptions(id);
// 	});
//
// 	const changeGeneralPlanBackgroundImage = (newGeneralPlanImage) => {
// 		let oldGeneralPlanImage = generalPlanWrapper.querySelector('svg image');
//
// 		return oldGeneralPlanImage.setAttribute('xlink:href', `${newGeneralPlanImage}`);
// 	}
//
// 	changeGeneralPlanBackgroundImage('../pic/genplan/genplan.jpg');
// }
//
// landGeneralPlan();
