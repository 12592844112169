(function () {
	const
		body = document.querySelector('body'),
		headerBurgerBtn = document.querySelector('.header__burger'),
		headerWrapper = document.querySelector('.header__wrapper'),
		mobileMenu = document.querySelector('.navigation__nav'),
		mobileMenuCrossBtn = document.querySelector('.navigation__cross-btn'),
		navigationLink = document.querySelectorAll('.navigation__link');

	window.addEventListener('scroll', () => {
		scrollY > 640
			? headerWrapper.classList.add('header__wrapper_fix')
			: headerWrapper.classList.remove('header__wrapper_fix');
	});

	headerBurgerBtn.addEventListener('click', showMobileMenu);

	mobileMenuCrossBtn.addEventListener('click', showMobileMenu);

	navigationLink.forEach(link => link.addEventListener('click', () => {
		body.classList.contains('popup-active') ? showMobileMenu() : null;
	}));

	function showMobileMenu() {
		body.classList.toggle('popup-active');
		mobileMenu.classList.toggle('show-mobile-menu');
	}
})();
