(function () {
	const newsSwiper = new Swiper('.news-swiper', {
		// Optional parameters
		direction: 'horizontal',
		slidesPerView: 1,
		centeredSlides: false,

		breakpoints: {
			475: {
				slidesPerView: 2,
				spaceBetween: 24,
			},
			744: {
				slidesPerView: 3,
				spaceBetween: 40,
			}
		},

		// Navigation arrows
		navigation: {
			nextEl: '.news-swiper-button-next',
			prevEl: '.news-swiper-button-prev',
		},
	});
})();

(function () {
	const body = document.querySelector('body');

	const news = document.querySelector('#news');
	const newsSwiperSlide = news.querySelectorAll('.news-swiper-slide');

	const modalNews = document.querySelector('.modal-news');
	const modalNewsCross = modalNews.querySelector('.modal-news__cross');
	const modalNewsImage = modalNews.querySelector('.modal-news__image');
	const modalNewsDate = modalNews.querySelector('.modal-news__date');
	const modalNewsTitle = modalNews.querySelector('.modal-news__title');
	const modalNewsDescription = modalNews.querySelector('.modal-news__description');

	const dataNews = JSON.parse(news.dataset.news);

	newsSwiperSlide.forEach(slide => {
		slide.addEventListener('click', e => showModalNews('open', e));
	});

	modalNewsCross.addEventListener('click', () => showModalNews('close'));

	function showModalNews(action, e = undefined) {
		if (action === 'open') {
			e.preventDefault();
			const idNews = e.target.getAttribute('id')
				? +e.target.getAttribute('id')
				: +e.target.parentNode.getAttribute('id');

			dataNews.some(news => {
				if (idNews === news.id) {
					const createtDate = new Date(news.createdAt);
					modalNewsImage.style.backgroundImage = `url("${news.preview.link}")`;
					modalNewsDate.innerText = `${createtDate.getDate() > 9 ? createtDate.getDate() : '0' + createtDate.getDate() }.${createtDate.getMonth() + 1 > 9 ? createtDate.getMonth() + 1 : '0' + (createtDate.getMonth() + 1)}.${createtDate.getFullYear()}`;
					modalNewsTitle.innerText = news.title;
					modalNewsDescription.innerHTML = news.content;

					body.classList.add('popup-active');
					modalNews.classList.remove('hidden');

					return true;
				}
				return false;
			});
		} else if (action === 'close') {
			body.classList.remove('popup-active');
			modalNews.classList.add('hidden');
		}
	}
})();
