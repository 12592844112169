(function () {
	var $ = jQuery;

	const moo1 = $('#popup_coll .moo');
	document.getElementById('focus').addEventListener('click', function () {
		const L0 = moo1[0].value.length;
		const L1 = moo1[1].value.length;
		const L2 = moo1[2].value.length;
		const L3 = moo1[3].value.length;
		if (L0 == 3 && L1 == 3 && L2 == 2 && L3 == 2) {
			return;
		}
		if (L0 < 3) {
			moo1[0].focus();
		} else if (L0 == 3 && L1 < 3) {
			moo1[1].focus();
		} else if (L0 == 3 && L1 == 3 && L2 < 2) {
			moo1[2].focus();
		} else if (L0 == 3 && L1 == 3 && L2 == 2) {
			moo1[3].focus();
		}
	});

	for (let i = 0; i < moo1.length; i++) {
		moo1[i].addEventListener('input', function() {

			this.value = this.value.replace(/\D/g, '');

			if (i == 0 && this.value.length == 3) {
				moo1[(i + 1)].focus();
			} else if (i == 1 && this.value.length == 3) {
				moo1[(i + 1)].focus();
			} else if (i == 2 && this.value.length == 2) {
				moo1[(i + 1)].focus();
			} else if (i == 3 && this.value.length == 2) {
				moo1[(i + 1)].focus();
			}
			if (moo1[3].value.length > 2) {
				moo1[3].value = moo1[3].value.slice(0, 2);
			}
		});

		moo1[i].addEventListener('keyup', function (e) {
			if (e.key.match(/Backspace/ig) && this.value.length == 0 && i > 0) {
				moo1[(i - 1)].focus();
			}
		});
	}

	$('.js-sendform--submit').click(function (event) {
		event.preventDefault();
		checkSubmitForm($(event.currentTarget));
	});

	function checkSubmitForm($this) {
		let $thisFormLayer = $this.parents('.js-sendform--wrapper'), // оборачиваем все что касается формы
			$thisForm = $thisFormLayer.find('.js-sendform--section'), // начальный блок (с полями, заголовками)
			$thisSuccess = $thisFormLayer.find('.js-sendform--success'), // блок после успешной отправки
			$thisErrorMessage = $thisFormLayer.find('.js-sendform--error_message'), // поле для вывода сообщения если форма не отправилась
			$thisName = $thisFormLayer.find('.js-sendform--clientName').val(),
			$thisMessage = $thisFormLayer.find('.js_form_message').val(),
			$thisMessageBlock = $thisFormLayer.find('.js_form_message'),
			$thisNameInput = $thisFormLayer.find('.js-sendform--clientName'),
			$thisPhoneOne = $thisFormLayer.find('.js_phone_one').val(),
			$thisPhoneTwo = $thisFormLayer.find('.js_phone_two').val(),
			$thisPhoneThree = $thisFormLayer.find('.js_phone_three').val(),
			$thisPhoneFour = $thisFormLayer.find('.js_phone_four').val(),
			$thisPhone = '+7' + '(' + $thisPhoneOne + ')' + $thisPhoneTwo  + '-' +  $thisPhoneThree  + '-' +  $thisPhoneFour,
			$thisNameBlock = $thisFormLayer.find('.js-sendform--clientName'),
			$thisPhoneBlock = $thisFormLayer.find('.js_tel_wrap'),
			$thisParentModal = $('#requestСall');

		function submitForm() {
			if (!$thisMessageBlock.length) {
				$.ajax({
					type: 'POST',
					url: "/send_order",
					headers: {"Content-type": "application/x-www-form-urlencoded"},
					data: {firstname: $thisName, tel: $thisPhone},
					beforeSend: function () {
						$this.attr('disabled', 'disabled');
						console.log($thisParentModal);
						$thisParentModal.addClass('request-call--thanks');
						$thisForm.hide();
						$thisSuccess.show();
					},
					success: function () {
						$thisForm.hide();
						$thisSuccess.show();
					},
					error: function () {
						$this.removeAttr('disabled');
						$thisErrorMessage.text('Ошибка отправки. Попробуйте снова');
					}
				});
			} else {
				$.ajax({
					type: 'POST',
					url: "/send_order",
					headers: {"Content-type": "application/x-www-form-urlencoded"},
					data: {message: $thisMessage, tel: $thisPhone},
					beforeSend: function () {
						$this.attr('disabled', 'disabled');
						$thisForm.hide();
						$thisSuccess.show();
					},
					success: function () {
						$thisForm.hide();
						$thisSuccess.show();
					},
					error: function () {
						$this.removeAttr('disabled');
						$thisErrorMessage.text('Ошибка отправки. Попробуйте снова');
					}
				});
			}
		}

		if (($thisNameInput.length)) {
			if (($thisName === '') && ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '')) {
				$thisNameBlock.addClass('input-text--error');
				$thisPhoneBlock.addClass('input-text--error');
			} else if ($thisName === '') {
				$thisPhoneBlock.removeClass('input-text--error');
				$thisNameBlock.addClass('input-text--error');
			} else if ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '') {
				$thisNameBlock.removeClass('input-text--error');
				$thisPhoneBlock.addClass('input-text--error');
			} else {
				submitForm();
			}
		} else if (!$thisNameInput.length) {
			if ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '') {
				$thisPhoneBlock.addClass('input-text--error');
			} else {
				submitForm();
			}
		}

		return false;
	}
})();
